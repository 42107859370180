import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faFilePen, faUserGear, faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import useAuth from '../hooks/useAuth'
import useLang from '../hooks/useLang'
import useTranslate from '../hooks/useTranslate'
import PulseLoader from 'react-spinners/PulseLoader'

const DASH_REGEX = /^\/dash(\/)?$/
const USERS_REGEX = /^\/dash\/users.*?$/
const NOTES_REGEX = /^\/dash\/notes.*?$/
const CRONS_REGEX = /^\/dash\/crons.*?$/

const DashHeader = () => {
    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
    }, [isSuccess, navigate])

    const onUsersClicked = () => navigate('/dash/users')
    const onNotesClicked = () => navigate('/dash/notes')
    const onCronsClicked = () => navigate('/dash/crons')

    let dashClass = null
    if (!DASH_REGEX.test(pathname) && !USERS_REGEX.test(pathname) && !NOTES_REGEX.test(pathname) && !CRONS_REGEX.test(pathname)) {
        dashClass = "dash-header__container--small"
    }


    let userButton = null
    if (isManager || isAdmin) {
        userButton = (
            <button
                className="icon-button"
                title={T.dash.Users}
                onClick={onUsersClicked}
            >
                <FontAwesomeIcon icon={faUserGear} />
            </button>
        )
    }

    let notesButton = null
    notesButton = (
        <button
            className="icon-button"
            title={T.dash.Notes}
            onClick={onNotesClicked}
        >
            <FontAwesomeIcon icon={faFilePen} />
        </button>
    )

    let cronsButton = null
    cronsButton = (
        <button
            className="icon-button"
            title={T.dash.Crons}
            onClick={onCronsClicked}
        >
            <FontAwesomeIcon icon={faBell} />
        </button>
    )

    let logoutButton = null
    logoutButton = (
        <button
            className="icon-button"
            title={T.dash.Logout}
            onClick={sendLogout}
        >
            <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
    )

    const errClass = isError ? "errmsg" : "offscreen"

    let buttonContent
    if (isLoading) {
        buttonContent = <PulseLoader color={"#FFF"} />
    } else {
        buttonContent = (
            <>
                {cronsButton}
                {notesButton}
                {userButton}
                {logoutButton}
            </>
        )
    }

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <header className="dash-header">
                <div className={`dash-header__container ${dashClass}`}>
                    <Link to="/dash">
                        <h1 className="dash-header__title">CRONY</h1>
                    </Link>
                    <nav className="dash-header__nav">
                        {buttonContent}
                    </nav>
                </div>
            </header>
        </>
    )

    return content
}
export default DashHeader