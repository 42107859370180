export const TRANSLATIONS_FR = {
    "common": {
        "Open": "Ouvert",
        "Close": "Fermer",
        "Add": "Ajouter",
        "Edit": "Editer",
        "Save": "Sauvegarder",
        "Delete": "Supprimer",
        "Actived": "Activé",
        "Disabled": "Désactivé",
        "Created": "Créé le",
        "Updated": "Modifié le",
        "Back": "Retour",
    },
    "crons": {
        "CronsList": "Liste des crons",
        "Status": "Statut",
        "Title": "Task",
        "TitlePlaceholder": "ex: Task_name",
        "Description": "Description",
        "DescriptionPlaceholder": "ex: info...",
        "Interval": "Interval",
        "IntervalPlaceholder": "ex: * * * * *",
        "Method": "Method",
        "Url": "Requete (url)",
        "UrlPlaceholder": "ex: http://www.server.com/api/...",
        "Data": "Paramètre data (json)",
        "DataPlaceholder": "ex: { 'key1' : 'value1', 'key2' : 'value2' }",
        "Cron": "Cron",
        "Owner": "Auteur",
        "Edit": "Editer",
        "Completed": "Terminé",
        "NewCron": "Nouveau cron",
        "EditCron": "Edition cron",
        "Status": "Statut",
        "Result": "Result",
        "AssignedTo": "Assigné à",
        "DeleteThisCron": "Supprimer ce cron",
    },
    "notes": {
        "NotesList": "Liste des notes",
        "Status": "Statut",
        "Title": "Titre",
        "Note": "Note",
        "Owner": "Auteur",
        "Edit": "Editer",
        "Completed": "Terminé",
        "NewNote": "Nouvelle note",
        "EditNote": "Edition note",
        "Completed": "Terminé",
        "AssignedTo": "Assigné à",
        "DeleteThisNote": "Supprimer cette note",
    },
    "users": {
        "UsersList": "Liste des utilisateurs",
        "Username": "Utilisateurs",
        "Roles": "Rôles",
        "Edit": "Editer",
        "NewUser": "Nouvel utilisateur",
        "EditUser": "Edition utilisateur",
        "Username": "Pseudo",
        "Password": "Mot de passe",
        "AssignedRoles": "Rôles assignés",
        "Active": "Active",
        "DeleteThisUser": "Supprimer cet utilisateur",
    },
    "dash": {
        "Logout": "Déconnexion",
        "Welcome": "Bonjour",
        "Users": "Utilisateurs",
        "Notes": "Notes",
        "Crons": "Crons",
    },
}