import React from "react"


import { useGetCronsQuery } from "./cronsApiSlice"
import Cron from "./Cron"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons"

const CronsList = () => {
    useTitle('CRONY: Crons List')

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const {
        data: crons,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetCronsQuery('cronsList', {
        pollingInterval: 5000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        if (isManager || isAdmin) {
            content = (
                <>
                    <div><p className="errmsg">{error?.data?.message}</p></div>
                    <Link to="/dash/crons/new">
                        <button
                            className="icon-button"
                            title={T.common.Add}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </Link>
                </>
            )
        } else {
            content = <p className="errmsg">{error?.data?.message}</p>
        }
    }

    if (isSuccess) {
        const { ids, entities } = crons

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(cronId => entities[cronId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(cronId => <Cron cronId={cronId} />)

        let menuContent
        if (isManager || isAdmin) {
            menuContent = (
                <>
                    <Link to="/dash/crons/new">
                        <button
                            className="icon-button"
                            title={T.common.Add}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </Link>
                </>
            )
        }

        content = (
            <>
                <div className="form__title-row">
                    <h2>{T.crons.CronsList}</h2>
                    <div className="form__action-buttons">
                        {menuContent}
                    </div>
                </div>
                <table className="table table--crons">
                    <thead className="table__thead">
                        <tr>
                            <th scope="col" className="table__th">{T.crons.Status}</th>
                            <th scope="col" className="table__th">{T.crons.Title}</th>
                            <th scope="col" className="table__th">{T.crons.Result}</th>
                            <th scope="col" className="table__th">{T.crons.Edit}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent}
                    </tbody>
                </table>
            </>
        )
    }

    return content
}
export default CronsList