export const TRANSLATIONS_EN = {
    "common": {
        "Open": "Open",
        "Close": "Close",
        "Add": "Add",
        "Edit": "Edit",
        "Save": "Save",
        "Delete": "Delete",
        "Actived": "Activé",
        "Disabled": "Désactivé",
        "Created": "Created",
        "Updated": "Updated",
        "Back": "Back",
    },
    "crons": {
        "CronsList": "Liste des crons",
        "Status": "Status",
        "Title": "Task",
        "TitlePlaceholder": "ex: Task_name",
        "Description": "Description",
        "DescriptionPlaceholder": "ex: info...",
        "Interval": "Interval",
        "IntervalPlaceholder": "ex: * * * * *",
        "Method": "Method",
        "Url": "Requete (url)",
        "UrlPlaceholder": "ex: http://www.server.com/api/...",
        "Data": "Param data (json)",
        "DataPlaceholder": "ex: { 'key1' : 'value1', 'key2' : 'value2' }",
        "Cron": "Cron",
        "Owner": "Owner",
        "Edit": "Edit",
        "Completed": "Completed",
        "NewCron": "New cron",
        "EditCron": "Edit cron",
        "Status": "Status",
        "Result": "Result",
        "AssignedTo": "Assigned to",
        "DeleteThisCron": "Delete this cron",
    },
    "notes": {
        "NotesList": "Notes list",
        "Status": "Status",
        "Title": "Title",
        "Note": "Note",
        "Owner": "Owner",
        "Edit": "Edit",
        "Completed": "Completed",
        "NewNote": "New note",
        "EditNote": "Edit note",
        "Completed": "Completed",
        "AssignedTo": "Assigned to",
        "DeleteThisNote": "Delete this note",
    },
    "users": {
        "UsersList": "Users list",
        "Username": "Username",
        "Roles": "Roles",
        "Edit": "Edit",
        "NewUser": "New user",
        "EditUser": "Edit user",
        "Username": "Username",
        "Password": "Password",
        "AssignedRoles": "Assigned roles",
        "Active": "Active",
        "DeleteThisUser": "Delete this user",
    },
    "dash": {
        "Logout": "Logout",
        "Welcome": "Welcome",
        "Users": "Users",
        "Notes": "Notes",
        "Crons": "Crons",
    },
}