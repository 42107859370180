import { useState, useEffect } from "react"
import { useUpdateCronMutation, useDeleteCronMutation } from "./cronsApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan, faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

const EditCronForm = ({ cron, users }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateCron, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCronMutation()

    const [deleteCron, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteCronMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState(cron.cronTitle)
    const [description, setDescription] = useState(cron.cronDescription)
    const [interval, setInterval] = useState(cron.cronInterval)
    const [method, setMethod] = useState(cron.cronMethod)
    const [url, setUrl] = useState(cron.cronUrl)
    const [data, setData] = useState(cron.cronData)
    const [status, setStatus] = useState(cron.cronStatus)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTitle('')
            setDescription('')
            setInterval('')
            setMethod('')
            setUrl('')
            setData('')
            setStatus('')
            navigate('/dash/crons')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onPageBack = () => navigate('/dash/crons')

    const onTitleChanged = e => setTitle(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)
    const onIntervalChanged = e => setInterval(e.target.value)
    const onMethodChanged = e => setMethod(e.target.value)
    const onUrlChanged = e => setUrl(e.target.value)
    const onDataChanged = e => setData(e.target.value)
    const onStatusChanged = e => setStatus(prev => !prev)


    const canSave = [title, description, interval, method, url].every(Boolean) && !isLoading

    const onSaveCronClicked = async (e) => {
        if (canSave) {
            await updateCron({ id: cron.id, cronTitle: title, cronDescription: description, cronInterval: interval, cronMethod: method, cronUrl: url, cronData: data, cronStatus: status })
        }
    }

    const onDeleteCronClicked = async () => {
        await deleteCron({ id: cron.id })
    }

    const reqMethods = ['', 'GET', 'POST', 'PUT', 'PATCH', 'DELETE']
    const options_method = reqMethods.map(reqMethod => {
        return (
            < option
                key={reqMethod}
                value={reqMethod}
            > {reqMethod}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validDescriptionClass = !description ? "form__input--incomplete" : ''
    const validIntervalClass = !interval ? "form__input--incomplete" : ''
    const validMethodClass = !method ? "form__input--incomplete" : ''
    const validUrlClass = !url ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="icon-button"
                title={T.common.Delete}
                name="Delete"
                onClick={onDeleteCronClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </button>
        )
    }

    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2>{T.crons.EditCron}</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title={T.common.Save}
                            name="Save"
                            onClick={onSaveCronClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                        <button
                            className="icon-button"
                            title={T.common.Back}
                            name="Back"
                            onClick={onPageBack}
                        >
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="cron-title">
                    {T.crons.Title}:</label>
                <input
                    className={`form__input ${validTitleClass}`}
                    id="cron-title"
                    name="title"
                    type="text"
                    autoComplete="off"
                    value={title}
                    onChange={onTitleChanged}
                />

                <label className="form__label" htmlFor="description">
                    {T.crons.Description}:</label>
                <textarea
                    className={`form__input form__input--text ${validDescriptionClass}`}
                    id="description"
                    name="description"
                    placeholder={T.crons.DescriptionPlaceholder}
                    value={description}
                    onChange={onDescriptionChanged}
                />

                <label className="form__label" htmlFor="interval">
                    {T.crons.Interval}:</label>
                <input
                    className={`form__input ${validIntervalClass}`}
                    id="interval"
                    name="interval"
                    placeholder={T.crons.IntervalPlaceholder}
                    type="text"
                    autoComplete="off"
                    value={interval}
                    onChange={onIntervalChanged}
                />

                <label className="form__label form__checkbox-container" htmlFor="method">
                    {T.crons.Method}:</label>
                <select
                    id="method"
                    name="method"
                    className={`form__select ${validMethodClass}`}
                    value={method}
                    onChange={onMethodChanged}
                >
                    {options_method}
                </select>

                <label className="form__label" htmlFor="url">
                    {T.crons.Url}:</label>
                <input
                    className={`form__input ${validUrlClass}`}
                    id="url"
                    name="url"
                    placeholder={T.crons.UrlPlaceholder}
                    type="text"
                    autoComplete="off"
                    value={url}
                    onChange={onUrlChanged}
                />

                <label className="form__label" htmlFor="data">
                    {T.crons.Data}:</label>
                <textarea
                    className={`form__input form__input--text`}
                    id="data"
                    name="data"
                    placeholder={T.crons.DataPlaceholder}
                    value={data}
                    onChange={onDataChanged}
                />

                <div className="form__row">
                    <div className="form__divider">
                        <label className="form__label form__checkbox-container" htmlFor="status">
                            {T.crons.Status}:
                            <input
                                className="form__checkbox"
                                id="status"
                                name="status"
                                type="checkbox"
                                checked={status}
                                onChange={onStatusChanged}
                            />
                        </label>

                    </div>
                    <div className="form__divider">
                    </div>
                </div>
            </form>
            <br />
            <br />
            {deleteButton}{T.crons.DeleteThisCron}
        </>
    )

    return content
}

export default EditCronForm