import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetCronsQuery } from './cronsApiSlice'
import { memo, useState, useEffect, useRef } from 'react'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

const truncate = (input, nbcar) =>
    input?.length > nbcar ? `${input.substring(0, nbcar)}...` : input;


const Cron = ({ cronId }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [cell, setCell] = useState({ id: cronId, name: '' });

    const useHasChanged = (id, val) => {
        const myId = `${id}`
        const myVal = usePrevious(myId, val)
        //        console.log("myId = " + myId);
        //        console.log("myVal = " + myVal);
        if (myVal !== val) {
            console.log(myId + " has changed = " + myVal);
            setTimeout(() => {
                setCell({ ...cell, focus: 'tt' });

                setTimeout(() => {
                    setCell({ ...cell, focus: '' });
                }, 5000);

            }, 5);
        }
        return myVal !== val
    }

    const usePrevious = (id, val) => {
        var myRef = `ref_${id}`
        myRef = useRef();
        useEffect(() => {
            myRef.current = val;
        });
        return myRef.current;
    }


    const { cron } = useGetCronsQuery("cronsList", {
        selectFromResult: ({ data }) => ({
            cron: data?.entities[cronId]
        }),
    })

    const navigate = useNavigate()

    useHasChanged(cronId, cron.cronResultExec)

    if (cron) {

        // const created = new Date(cron.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })
        const updated = new Date(cron.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

        const handleEdit = () => navigate(`/dash/crons/${cronId}`)


        return (
            <tr className="table__row">
                <td className="table__cell cron__status">
                    {cron.cronStatus
                        ? <span className="cron__status--active">active</span>
                        : <span className="cron__status--inactive">inactive</span>
                    }
                </td>
                <td className="table__cell">
                    <b>TITLE:</b> {cron.cronTitle}<br />
                    <b>INTERVAL:</b> {cron.cronInterval}<br />
                    <b>REQUETE:</b> {cron.cronMethod} {cron.cronUrl}<br />
                    <b>DATA:</b> {cron.cronData}<br />
                </td>
                <td className={`table__cell ${cell.focus}`}>
                    <b>NB:</b> {cron.cronNbExec}<br />
                    <b>DATE:</b> {updated}<br />
                    <b>RESULT:</b> {truncate(cron.cronResultExec, 500)}<br />
                </td>
                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        title={T.common.Edit}
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr >
        )

    } else return null
}

const memoizedCron = memo(Cron)

export default memoizedCron