import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const cronsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = cronsAdapter.getInitialState()

export const cronsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCrons: builder.query({
            query: () => ({
                url: '/crons',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedCrons = responseData.map(cron => {
                    cron.id = cron._id
                    return cron
                });
                return cronsAdapter.setAll(initialState, loadedCrons)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Cron', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Cron', id }))
                    ]
                } else return [{ type: 'Cron', id: 'LIST' }]
            }
        }),
        addNewCron: builder.mutation({
            query: initialCron => ({
                url: '/crons',
                method: 'POST',
                body: {
                    ...initialCron,
                }
            }),
            invalidatesTags: [
                { type: 'Cron', id: "LIST" }
            ]
        }),
        updateCron: builder.mutation({
            query: initialCron => ({
                url: '/crons',
                method: 'PATCH',
                body: {
                    ...initialCron,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Cron', id: arg.id }
            ]
        }),
        deleteCron: builder.mutation({
            query: ({ id }) => ({
                url: `/crons`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Cron', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetCronsQuery,
    useAddNewCronMutation,
    useUpdateCronMutation,
    useDeleteCronMutation,
} = cronsApiSlice

// returns the query result object
export const selectCronsResult = cronsApiSlice.endpoints.getCrons.select()

// creates memoized selector
const selectCronsData = createSelector(
    selectCronsResult,
    cronsResult => cronsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCrons,
    selectById: selectCronById,
    selectIds: selectCronIds
    // Pass in a selector that returns the crons slice of state
} = cronsAdapter.getSelectors(state => selectCronsData(state) ?? initialState)