import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

const Welcome = () => {

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const date = new Date()
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date)

    const content = (
        <section className="welcome">

            <p>{today}</p>

            <h1>{T.dash.Welcome} {username}!</h1>

            <p><Link to="/dash/crons">{T.dash.Crons}</Link></p>

        </section>
    )

    return content
}
export default Welcome