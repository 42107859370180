import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login';
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'

import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'

import NotesList from './features/notes/NotesList'
import EditNote from './features/notes/EditNote'
import NewNote from './features/notes/NewNote'

import CronsList from './features/crons/CronsList'
import EditCron from './features/crons/EditCron'
import NewCron from './features/crons/NewCron'
//import PlayCrons from './features/crons/PlayCrons'

import Prefetch from './features/auth/Prefetch'

import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'

import RequireLang from './features/lang/RequireLang'
import { LANGS } from './config/langs'

import useTitle from './hooks/useTitle';

function App() {
    useTitle('CRONY')

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {/* public routes */}
                <Route index element={<Public />} />
                <Route path="login" element={<Login />} />

                {/* Protected Routes */}
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                        <Route element={<RequireLang allowedLangs={[...Object.values(LANGS)]} />}>
                            <Route element={<Prefetch />}>
                                <Route path="dash" element={<DashLayout />}>

                                    <Route index element={<Welcome />} />

                                    <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                                        <Route path="users">
                                            <Route index element={<UsersList />} />
                                            <Route path=":id" element={<EditUser />} />
                                            <Route path="new" element={<NewUserForm />} />
                                        </Route>
                                    </Route>

                                    <Route path="notes">
                                        <Route index element={<NotesList />} />
                                        <Route path=":id" element={<EditNote />} />
                                        <Route path="new" element={<NewNote />} />
                                    </Route>

                                    <Route path="crons">
                                        <Route index element={<CronsList />} />
                                        <Route path=":id" element={<EditCron />} />
                                        <Route path="new" element={<NewCron />} />
                                    </Route>

                                </Route>{/* End Dash */}
                            </Route>
                        </Route>
                    </Route>
                </Route>{/* End Protected Routes */}

            </Route>
        </Routes >
    );
}

export default App;