import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewCronMutation } from "./cronsApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

const NewCronForm = ({ users }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewCron, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCronMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [interval, setInterval] = useState('')
    const [method, setMethod] = useState('')
    const [url, setUrl] = useState('')
    const [data, setData] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setTitle('')
            setDescription('')
            setInterval('')
            setMethod('')
            setUrl('')
            setData('')
            navigate('/dash/crons')
        }
    }, [isSuccess, navigate])

    const onPageBack = () => navigate('/dash/crons')

    const onTitleChanged = e => setTitle(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)
    const onIntervalChanged = e => setInterval(e.target.value)
    const onMethodChanged = e => setMethod(e.target.value)
    const onUrlChanged = e => setUrl(e.target.value)
    const onDataChanged = e => setData(e.target.value)

    const canSave = [title, description, interval, method, url].every(Boolean) && !isLoading

    const onSaveCronClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewCron({ cronTitle: title, cronDescription: description, cronInterval: interval, cronMethod: method, cronUrl: url, cronData: data })
        }
    }

    const reqMethods = ['', 'GET', 'POST', 'PUT', 'PATCH', 'DELETE']
    const options_method = reqMethods.map(reqMethod => {
        return (
            < option
                key={reqMethod}
                value={reqMethod}
            > {reqMethod}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validDescriptionClass = !description ? "form__input--incomplete" : ''
    const validIntervalClass = !interval ? "form__input--incomplete" : ''
    const validMethodClass = !method ? "form__input--incomplete" : ''
    const validUrlClass = !url ? "form__input--incomplete" : ''

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveCronClicked}>
                <div className="form__title-row">
                    <h2>{T.crons.NewCron}</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            name="Save"
                            title={T.common.Save}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                        <button
                            className="icon-button"
                            title={T.common.Back}
                            name="Back"
                            onClick={onPageBack}
                        >
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </button>
                    </div>
                </div>

                <label className="form__label" htmlFor="title">
                    {T.crons.Title}:</label>
                <input
                    className={`form__input ${validTitleClass}`}
                    id="title"
                    name="title"
                    placeholder={T.crons.TitlePlaceholder}
                    type="text"
                    autoComplete="off"
                    value={title}
                    onChange={onTitleChanged}
                />

                <label className="form__label" htmlFor="description">
                    {T.crons.Description}:</label>
                <textarea
                    className={`form__input form__input--text ${validDescriptionClass}`}
                    id="description"
                    name="description"
                    placeholder={T.crons.DescriptionPlaceholder}
                    value={description}
                    onChange={onDescriptionChanged}
                />

                <label className="form__label" htmlFor="interval">
                    {T.crons.Interval}:</label>
                <input
                    className={`form__input ${validIntervalClass}`}
                    id="interval"
                    name="interval"
                    placeholder={T.crons.IntervalPlaceholder}
                    type="text"
                    autoComplete="off"
                    value={interval}
                    onChange={onIntervalChanged}
                />

                <label className="form__label form__checkbox-container" htmlFor="method">
                    {T.crons.Method}:</label>
                <select
                    id="method"
                    name="method"
                    className={`form__select ${validMethodClass}`}
                    value={method}
                    onChange={onMethodChanged}
                >
                    {options_method}
                </select>

                <label className="form__label" htmlFor="url">
                    {T.crons.Url}:</label>
                <input
                    className={`form__input ${validUrlClass}`}
                    id="url"
                    name="url"
                    placeholder={T.crons.UrlPlaceholder}
                    type="text"
                    autoComplete="off"
                    value={url}
                    onChange={onUrlChanged}
                />

                <label className="form__label" htmlFor="data">
                    {T.crons.Data}:</label>
                <textarea
                    className={`form__input form__input--text`}
                    id="data"
                    name="data"
                    placeholder={T.crons.DataPlaceholder}
                    value={data}
                    onChange={onDataChanged}
                />

            </form>
        </>
    )

    return content
}

export default NewCronForm