import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useLang = () => {
    const token = useSelector(selectCurrentToken)
    let isLangEN = false
    let isLangFR = false
    let lang = "en"

    if (token) {
        const decoded = jwtDecode(token)
        //console.log(decoded)
        const { langs } = decoded.UserInfo

        isLangEN = langs.includes('en')
        isLangFR = langs.includes('fr')

        if (isLangEN) lang = "en"
        if (isLangFR) lang = "fr"

        //console.log(langs, lang, isLangEN, isLangFR)
        return { langs, lang, isLangEN, isLangFR }
    }

    return { langs: [], lang, isLangEN, isLangFR }
}

export default useLang